.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, #a0fdfd, #00ffff);
    /* background: linear-gradient(90deg, #000000, #64017a); */
    transform-origin: 0%;
    z-index: 9999;
}