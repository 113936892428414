.testimonialContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.testimonialItem {
    text-align: center;
    width: 100%;
    padding: 0;
    background-color: rgba(88, 1, 108, .5) !important;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(82, 255, 228, 0.7);
    border: 2px solid rgba(0, 255, 255, 0.7);
    border-radius: 8px;
    margin-bottom: 20px;

}

.testimonialImages {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.testimonialImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.testimonialImage {
    width: 100%;
    height: 100%;
    margin: 0;
    border-bottom: 1px solid var(--asterius-blue);
}

.testimonialImageContainer:first-child .testimonialImage {
    box-sizing: border-box;
    border-radius: 8px 0 0 0;
    border-right: 2px solid #00ffff;
    /* No radius on the top right and bottom right */
}

.testimonialImageContainer:last-child .testimonialImage {
    border-radius: 0 8px 0 0;
    /* No radius on the top left and bottom left */
}

.imageLabel {
    margin: 0;
    margin-top: 5px;
    font-size: 16px;
    color: #fff;
}

.testimonialText {
    font-size: 1.2rem;
    color: #fff;
    margin: 0;
    font-size: 15px !important;
    line-height: 22px !important;
}

.testimonialName {
    margin: 0;
    margin-top: 10px;
    font-size: 15px;
    line-height: 28px;
    font-weight: bold;
    color: #fff;
}

.sliderControls {
    display: flex;
    justify-content: center;

    button {
        background-color: #58016c;
        color: #fff !important;
        border: none;
        padding: 7px 13px;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);

        &:hover {
            background-color: #7b1fa2;
        }

        span {
            z-index: 1000;
        }

        img {
            width: 20px;
        }

        .rightArrow {
            transform: rotate(-90deg) !important;
        }

        .leftArrow {
            transform: rotate(-270deg) !important;
        }
    }
}



.prevButton {
    margin-right: 10px;
}

.nextButton {
    margin-left: 10px;
}
