.agreementSection {
    padding: 20px;
    background-color: #f5f5f5; 
}

.agreementAccordionItem {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.agreementQuestion {
    padding: 10px;
    background-color: #302b63;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 0;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(82, 255, 228, 0.7);
    border: 2px solid rgba(0, 255, 255, 0.7);
    transition: background-color 0.3s ease, border 0.3s ease;
}

.agreementQuestion:active,
.agreementQuestion:focus {
    background-color: #302b63;
}

.agreementAnswer {
    color: #4e005f;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease, padding 0.3s ease;
    padding: 0 20px;
    border-bottom: 2px solid #00ffff;
    border-left: 2px solid #00ffff;
    border-right: 2px solid #00ffff;
    background: linear-gradient(180deg, #000000, #27083f 70%, #321F45);
    line-height: 28px;
}

.agreementAnswer.expanded {
    height: auto;
    /* This needs to be calculated dynamically in JS */
    padding: 20px;
}

.agreementIndicator {
    font-size: 1.2rem;
    color: #00ffff;
}

.noBottomBorder {
    border-bottom: none !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

