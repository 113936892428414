.account-deletion-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .account-deletion-content-container {
        padding: 30px;
        max-width: 500px;
        margin: 0 auto;
    }

    .delete-main-header {
        font-size: 28px;
        text-align: center;
        padding-bottom: 0px;
        padding-top: 0px;
        margin-top: 0;
    }

    .delete-header1 {
        font-weight: bold;
        margin-bottom: 15px;
        text-align: center;
    }

    .deletion-p {
        font-size: 19px;
    }

    .confirmation {
        margin: 15px 0;
    }

    .email-delete-input {
        padding: 8px;
        margin-top: 10px;
        margin-left: 1px;
        font-size: 16px;
        width: 250px;
    }

    /* App.css */

    /* Existing styles... */

    .confirmation {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .large-checkbox {
        /* Scale the checkbox */
        transform: scale(1.5);
        -ms-transform: scale(1.5);
        /* IE 9 */
        -webkit-transform: scale(1.5);
        /* Safari */
        margin-right: 10px;
        /* Space between checkbox and label */
        cursor: pointer;
    }

    /* Optional: Adjust the label font size for better readability */
    .confirmation label {
        font-size: 1rem;
    }

    /* Style for the submit button */
    button[type="submit"] {
        padding: 10px 20px;
        background-color: #d9534f;
        /* Bootstrap danger color */
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;
    }

    button[type="submit"]:hover {
        background-color: #c9302c;
    }

    /* Success and error messages */
    .success-message {
        color: green;
        margin-top: 20px;
    }

    .error-message {
        color: red;
        margin-top: 20px;
    }

    /* Additional info styling */
    .additional-info {
        margin-top: 30px;
        text-align: center;
    }

    .additional-info a {
        color: #0275d8;
        /* Bootstrap primary color */
        text-decoration: none;
    }

    .additional-info a:hover {
        text-decoration: underline;
    }

    /* Allow the content container to expand and take up available space */
    .account-deletion-content-container {
        flex: 1;
    }

    /* Update the footer to position it correctly */

    button[disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }
    
    
}