:root {
    --asterius-blue: #00ffff;
    --asterius-purple: #302b63;
}

body {
    font-family: Inter, Helvetica, Apple Color Emoji, Segoe UI Emoji, NotoColorEmoji, Noto Color Emoji, Segoe UI Symbol, Android Emoji, EmojiSymbols, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Noto Sans, sans-serif !important;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    text-align: center;
}

button {
    text-shadow: 1px 1px 1px #fff;
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f8f8;
    border-bottom: 1px solid var(--asterius-purple);
    position: sticky;
    top: 0;
    z-index: 1000;
    /* Ensure the header is above other content */
}

.menu-header .logo {
    font-size: 20px;
    /* font-weight: 500; */
    color: #34093f;
    img {
        margin-bottom: -8px;
        width: 30px;
        
        /* margin-right: 3px; */
        /* margin-right: 5px; */
        margin-right: 4px;
    }
    span {
        /* text-shadow: 1.7px 1.2px 1px #00ffff; */
        text-shadow: 2px 1.5px 1px #00ffff;
        text-decoration: underline;
        text-underline-offset: 3.5px;
        text-decoration-thickness: 1px;
    }
}

.menu-header .cta-button button {
    padding: 8px 15px;
    background-color: #302b63;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #7970d6;
    font-size: 15px;
}

.menu-header .cta-button button:hover {
    background-color: #302b63;
}

.header {
    /* background-color: var(--asterius-purple); */

    /* background: linear-gradient(135deg, #0f0c29, #302b63, #24243e); */

    /* background: linear-gradient(180deg, #000000, #2B0134 40%, #6314A0 70%, #321F45); */

    /* background: linear-gradient(180deg, #23002b, #2B0134 40%, #460f70 70%, #321F45); */
    
    /* background: linear-gradient(180deg, #15001a, #2B0134 40%, #460f70 70%, #321F45);

    background: linear-gradient(180deg, #15001a, #4b015c 50%, #460f70 70%, #321F45);
    background: linear-gradient(180deg, #15001a, #4b015c 50%, #27083f 70%, #321F45);
    background: linear-gradient(180deg, #15001a, #66017c 60%, #27083f 70%, #321F45); */
    

   /*  background: linear-gradient(180deg, #000000, #64017a 60%, #27083f 70%, #fff); */
    background: linear-gradient(180deg, #000000, #64017a 60%, #27083f 70%, #321F45);
    


    padding: 20px;
    color: white;

    position: relative;
    /* This makes the child's absolute position relative to this container */
    
    /* min-height: 100vh; */
    
    /* Full height of the viewport */

    p {
        font-size: 26px;
    }

    .bottom-phone-image {
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: block;
        margin-top: 25px;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }


    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.header h1 {
    margin: 0;
    font-size: 43px;
    
}

.ion-title {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    background-repeat: no-repeat;
    color: #000000;
    
    letter-spacing: -2.3px;
    line-height: 23px;

    /* background-image: linear-gradient(90deg, #00FFFF 0%, #f6ffff 100%); */

    /* background-image: linear-gradient(90deg, #00FFFF 0%, #e3fcfc 105%); */
    background-image: linear-gradient(90deg, #00FFFF 0%, #e3fcfc 110%);
    word-spacing: 5px;
    /* font-weight: normal !important; */
    font-weight: 800 !important;
    line-height: 43px;
}

.ion-title-whiter {
    font-size: 41px;
    font-weight: 650;

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    background-repeat: no-repeat;
    color: #000000;
    letter-spacing: -2.3px;
    line-height: 23px;
    
    /* background-image: linear-gradient(90deg, #aefcfc 0%, #e3fcfc 110%);
    background-image: linear-gradient(90deg, #327777 0%, #e3fcfc 110%); */

    background-image: linear-gradient(90deg, #b2ffff 0%, #e3fcfc 110%);
    
    word-spacing: 5px;
    line-height: 43px;
    
}

.ion-title-small-text-white, .ion-title-small-text-blue {

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    background-repeat: no-repeat;
    color: #000000;
    line-height: 23px;

    background-image: linear-gradient(90deg, #b2ffff 0%, #e3fcfc 100%);
    font-weight: 600;
    line-height: 33px;
}

.ion-title-purple {
    background-image: linear-gradient(180deg, #000000, #64017a 60%, #27083f 70%, #321F45);
    background-image: linear-gradient(90deg, #3f1163 0%, #510363 100%);
    /* special color: #64017a */
}

.ion-title-small-text-blue {
    font-weight: 700;
    /* background-image: linear-gradient(90deg, #00FFFF 0%, #b4fafa 100%); */
    background-image: linear-gradient(90deg, #00FFFF 0%, #75ecec 100%);
}

.stakes-section,
.benefits-section,
.guide-section,
.plan-section,
.explanatory-section,
.video-section,
.price-section,
.junk-drawer-section {
    /* padding: 20px; */
    border-bottom: 1px solid #ddd;

    ul,
    ul,
    ol {
        display: inline-block;
        /* Ensure the list itself is centered */
        text-align: left;
        /* Align the text within the list to the left */
        padding-left: 20px;
        /* Add padding to align the list items correctly */
    }
}

h2 {
    color: #333;
    font-size: 30px;
}

.guide-section {

    /* ul {
        list-style: none;
        padding-left: 0;
    }

    ul>li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        text-shadow: 0px 0px 1px #00FFFF;
    }

    ul>li::before {
        content: "•";
        color: #00ffff;
        position: absolute;
        left: 0;
        top: 0;
    } */

    color: #fff;
    border-top: 1px solid #00ffff;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    margin: 0 auto;

    .guide-paragraph {
        font-size: 20px;
        line-height: 30px;
    }

    ul {
        list-style: none;
        margin: 0 auto;
        padding-left: 0;
        margin-bottom: 20px;
    }

    li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 18px !important;
        flex-wrap: nowrap;
        /* Ensure items don't wrap */
    }

    li svg {
        margin-right: 10px;
        flex-shrink: 0;
        vertical-align: middle;
        margin-bottom: -5px;
    }

    .ion-title-small-text-white {
        display: inline-flex;
        /* Ensure inline-flex for horizontal alignment */
        flex-wrap: nowrap;
        align-items: center;
    }

    .asterius-text-blue-override-graident-text {
        margin-left: 5px;
        /* Optional: add some space if needed */
        white-space: nowrap;
        /* Ensure text doesn't wrap */
    }

    .special {
        font-style: italic;
    }


}

.explanatory-section {
    color: #fff;
    font-size: 18px;
    border-top: 1px solid #00ffff;
    
    .explanatory-paragraph-container {
        .asterius-text-blue-override-graident-text {
            font-weight: bold;
        }
        padding: 20px;
    }
}

.custom-hr, .custom-hr-purple {
    border: none;
    height: 1px;
    background-color: #52ffe4;
    /* width: 50%; */
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
}

.custom-hr-purple {
    background-color: #a002c4;
    background-color: #7b0596;
}

button {
    padding: 10px 20px;
    background-color: #302b63;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

footer {
    background-color: #282c34;
    padding: 10px;
    color: white;
}

video {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.arrow-img {
    display: block;
    margin: 15px auto;
    width: 20px;
}

.asterius-text-blue {
    color: var(--asterius-blue);
    font-weight: bold;
}

.asterius-text-blue-override-graident-text {
    color: var(--asterius-blue);
    background: unset !important;
    -webkit-text-fill-color: #00ffff;
}

.asterius-text-purple-override-graident-text {
    background: unset !important;
    color: #7b0596;
    -webkit-text-fill-color: #7b0596;
}

.asterius-background-purple {
    background-color: var(--asterius-purple);
}

.sweet-button {
    background: #00FFFF !important;
    color: rgba(48, 43, 99, .9) !important;
    letter-spacing: -.4px;
    word-spacing: .5px;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
    border: 2px solid transparent;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5) !important;
    border: 1px solid rgba(0, 255, 255, 0.7);

    &.larger {
        font-size: 23px !important;
        width: 70%;
        max-width: 300px;
        border: 2px solid #fff;
    }
}

.bottom-of-header {
    font-size: 23px !important;

    position: absolute;
    bottom: 0;
    /* Aligns the button at the bottom */
    margin-bottom: 20px;
    /* Adds some space from the bottom */
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
    /* Offset the button by half its width to truly center it */
    /* Responsive margin example */
    margin-bottom: calc(2vh + 100px);
    width: 90%;
}

li {
    padding-bottom: 15px;
}

li:last-child {
    padding-bottom: 0;
}

.container > * {
    max-width: 450px;
    margin: 0 auto;
}

.container-white > * {
    max-width: 450px;
    margin: 0 auto;
}

.container, .container-white {
    background: linear-gradient(180deg, #000000, #64017a 60%, #27083f 70%, #321F45);
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    /* height: 300vh; */ 

    /* update this and .section vh if you want it showing up earlier in the viewport */
    /* height: 225vh;
    height: 150vh;
    height: 120vh;

    height: 100vh; */
    /* height: 80vh; */
    /* height: 100vh; */


    h2 {
        font-size: 30px;
        line-height: 45px !important;
        color: #fff;
        line-height: 55px;
        padding: 20px;
        font-weight: 600 !important;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    h3 {
        margin: 0 auto;
    };

    .section-text {
        /* font-size: 27px;
        line-height: 40px !important; */
        font-size: 22px;
        line-height: 30px !important;
    }

    .container-header {
        line-height: 40px !important;     
        font-weight: 600 !important;
        font-size: 30px;
        padding: 20px;
    }


    /* Adjust as needed */
}

.container-white {
    background: #cafcfc;
    background: #d3fdfd;
   /*  background: rgba( 0, 255, 255, 0.2 ); */
    /* background: linear-gradient(180deg, #fff, #8efdfd 50%, #2efafa); */
    
    /* background: linear-gradient(180deg, #fff, #8efdfd 50%, #2efafa 60%, #fff); */

    /* background: linear-gradient(180deg, #fff, #8efdfd 50%, #8efdfd 60 #fff); */
    background: linear-gradient(180deg, #fff,  #8efdfd 70%, #fff);
    background: linear-gradient(180deg, #fff, #b4fcfc 30%, #8efdfd 60%, #fff);
}

.stake-section, .benefit-section {
    position: relative;
    /* height: 100vh; */ 

    /* update this and .container vh if you want it showing up earlier in the viewport */
    height: 75vh; 
    height: 50vh; 
    /* height: 33vh;  */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 544px;
    margin: 0 auto;
    max-height: 180px;
}

.expanding-text {
  
    text-align: center;
    opacity: 0;
    /* Start invisible */
    transition: transform 2s, font-size 2s, opacity 2s;

    margin: 20px;
  
    
    /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), 0 0 10px rgba(82, 255, 228, 0.1); */

        
/*     border-left: 1px solid rgba(0, 255, 255, 0.5);
    border-right: 1px solid rgba(0, 255, 255, 0.5);
     */


       
}

.expanding-text::before,
.expanding-text::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    /* Width of the glow effect */
    background: rgba(0, 255, 255, 0.3);
    box-shadow: 0 0 15px rgba(0, 255, 255, 1);
}

.expanding-text-purple::before,
.expanding-text-purple::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    /* Width of the glow effect */
    background: rgba(100, 1, 122, 1);
    box-shadow: 0 0 15px rgba(127, 17, 224, 1);

    background: rgb(89, 0, 109);
    background: rgb(71, 0, 87);
        box-shadow: 0 0 15px rgba(127, 17, 224, 1);
}

.expanding-text::before {
    left: 0px;
    /* Position it slightly to the left */
}

.expanding-text::after {
    right: 0px;
    /* Position it slightly to the right */
}

.stakes-section {
    border-top: 1px solid #00ffff;
}

.crazy-but {
    font-style: italic;
    margin: 0;
    animation-delay: .5s;
}

.icon-wrapper {
    display: inline-block;
}

.icon {
    width: 24px;
    height: 24px;
}

/* .agreement-plan-answer-override-text {
    font-weight: bold;
    background: unset !important;
    color: #750f8b;
    -webkit-text-fill-color: #911faa;
    text-shadow: 1px 2px 1px rgba(0, 255, 255, 1);
    
} */

.yarl__button {
    background-color: transparent !important;
    /* Remove any background color */
    
}

/* Ensure the arrow icons are visible */
.yarl__button svg {
    fill: #fff !important;
    /* Set arrow color to white, or any color you prefer */
}

/* Optional: Remove any border or outline on focus */
.yarl__button:focus {
    outline: none !important;
}

.yarl__icon {
    height:50px;
    width: 50px;
}

.yarl__container {
    background-color: rgba(0, 0, 0, 0.8) !important;
    /* Black background with 80% transparency */
    img {
        box-sizing: border-box;
        border-radius: 10px;
        border: 2px solid #00ffff;
    }
}

.footer-for-short-page {
    position: relative;
    /* Remove absolute and fixed positioning */
    bottom: auto;
    /* Reset bottom */
    display: block;
    /* Remove fixed display */
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background-color: #282c34;
    /* Ensure background color is retained */
    color: white;
    /* Ensure text color is retained */
}