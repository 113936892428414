@keyframes scaleIn {
    0% {
        opacity: 0;
        transform: scale(.5)
    }

    20% {
        opacity: 15%
    }

    40% {
        opacity: 1;
        transform: scale(114%)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

.scale-in-animation {
    animation: scaleIn 1s ease-in-out forwards;
    animation-delay: .2s
}

@keyframes slideInUp {
    0% {
        opacity: 0;
        transform: translateY(100px)
    }

    20% {
        opacity: 15%
    }

    40% {
        opacity: 1;
        transform: translateY(-3px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.hidden {
    opacity: 0;
}

.slide-in-up-animation {
    animation: slideInUp 1.25s ease-in-out forwards;
    animation-delay: 1.1s
}

@keyframes slideInDown {
    0% {
        opacity: .0;
        transform: translateY(-100px)
    }

    20% {
        opacity: 15%
    }

    40% {
        opacity: 1;
        transform: translateY(3px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.slide-in-down-animation {
    animation: slideInDown 1s ease-in-out forwards;
    animation-delay: .2s
}

/*
new scrolly stuff */

@keyframes growText {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    50% {
        opacity: .5;
        transform: scale(1.25);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.grow-text-animation {
    animation: growText 1.25s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-in-animation {
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: .2s
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.zoom-in-animation {
    animation: zoomIn 1s ease-in-out forwards;
}

@keyframes bounceIn {

    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.bounce-in-animation {
    animation: bounceIn 1s ease-in-out forwards;
}

@keyframes rotateIn {
    0% {
        opacity: 0;
        transform: rotate(-200deg);
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}

.rotate-in-animation {
    animation: rotateIn 1s ease-in-out forwards;
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.slide-in-right-animation {
    animation: slideInRight 1s ease-in-out forwards;
}

@keyframes flipInX {
    0% {
        opacity: 0;
        transform: perspective(400px) rotateX(90deg);
    }

    100% {
        opacity: 1;
        transform: perspective(400px) rotateX(0deg);
    }
}

.flip-in-x-animation {
    animation: flipInX 1s ease-in-out forwards;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.pulse-animation {
    animation: pulse 1s ease-in-out infinite;
}

@keyframes swing {
    20% {
        transform: rotate3d(0, 0, 1, 15deg);
    }

    40% {
        transform: rotate3d(0, 0, 1, -10deg);
    }

    60% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, -5deg);
    }

    100% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

.swing-animation {
    animation: swing 1s ease-in-out forwards;
}

@keyframes dissolveIn {
    0% {
        opacity: 0;
        filter: blur(10px);
    }

    50% {
        opacity: 0.5;
        filter: blur(5px);
    }

    100% {
        opacity: 1;
        filter: blur(0);
    }
}

.dissolve-in-animation {
    animation: dissolveIn 1s ease-in-out forwards;
}