/* Pricing.module.css */
.pricingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
}

.pricingSave {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0;
}

.pricingTitle {
    font-size: 2rem;
    color: #fff;
    margin-bottom: 10px;
    font-weight: bold;
}

.pricingPrice {
    font-size: 35px;
    color: #fff;
    margin-bottom: 20px;
    color: #00ffff;
    font-weight:600;
}

.pricingFeatureList {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0px;
    width: 100%;
}

.pricingFeatureItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #fff;
    text-align: left;
    font-size: 18px;

    p {
        margin: 0;;
    }
}

.pricingFeatureIcon {
    color: var(--asterius-blue);
    margin-right: 10px;
    font-size:30px;
}

.pricingCtaButton {
    background-color: var(--asterius-blue);
    color: var(--asterius-purple);
    border: none;
    padding: 15px 30px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.pricingGuarantee {
    margin-top: 20px;
    font-size: 0.8rem;
    color: #555;
    text-align: center;
}

.pricingDisclaimer {
    font-size: 0.8rem;
    color: #555;
    text-align: center;
    margin-top: 10px;
}

.pricingPriceNew {
    font-size: 35px !important;
    margin: 0;
}

.usuallyPriceText {
    color: #e6e4e4;
    font-size: 23px;
    font-weight: 600;
}

.pricingOriginalPrice {
    font-size: 25px;
    color: #dad9d9;
    text-decoration: line-through;
    margin-bottom: 15px;
    font-weight: 600;
}

