/* Plan.module.css */
.planContainer {

    justify-content: space-around;
    padding: 20px;
    background: linear-gradient(180deg, #000000, #64017a 60%, #27083f 70%, #321F45);
    flex-wrap: wrap;
    /* Ensure items wrap to the next line */
    color: #fff;
    border-top: 1px solid #00ffff;
    text-align: center;
    margin: 0 auto;
}

.planItem {
    text-align: center;
    padding: 15px 25px;
    background-color: rgba(88, 1, 108, .5);
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(82, 255, 228, 0.7);
    border: 2px solid rgba(0, 255, 255, 0.7);
    border-radius: 8px;

    /* Add space between stacked items */
}

.planIcon {
    font-size: 2.5rem;
    color: var(--asterius-blue);
    margin-top: 10px;
}

.planHeaderH2 {
    margin: 10px 0 5px 0;
}

.planHeader {
    font-size: 1.5rem;
    margin: 10px 0;
}

.planSubtext {
    font-size: 20px;
    color: #fff;
}

/* Media query for tablet size and below */
@media (min-width: 768px) {
    .planItem {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        /* Make each item take full width */
    }
}